
import { Vue, Component, Watch } from "vue-property-decorator";
import {  DespesaService, RelatorioService } from "@/core/services/financeiro";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { EmpreendimentoService} from "@/core/services/cadastros";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";
import { formatDate } from "@/assets/scripts/helper";
 
@Component
export default class ListaDespesa extends mixins(Vue, ListPage) {
  painel = new PainelModel();
  service = new DespesaService();
 
  titulo: string = "Despesa";
  subTitulo: string = "Lista das despesas cadastradas no Sistema";
  NomeDataInicial = "Data Inicial";
  NomeDataFinal = "Data Final";
  loading: boolean = false;
  dateInicial: string | null = "";
  dateFinal: string | null = "";
  currentYear = new Date().getFullYear();
  currentMonths = new Date().getMonth();
  firstDayOfNextMonth = new Date(this.currentYear, this.currentMonths + 1, 1);
  lastDayOfCurrentMonth2 = new Date(this.firstDayOfNextMonth.getTime() - 1);
  lastDayOfCurrentMonth = new Date(this.firstDayOfNextMonth.getTime() - 1).getDate();
  formattedDateInicial: string | null = formatDate(new Date(this.currentYear, this.currentMonths, 1), 'yyyy-MM-dd');
  formattedDateFinal: string | null = formatDate(this.lastDayOfCurrentMonth2, 'yyyy-MM-dd');
  isProcessing: boolean = false;

  menuInicial: any = false;
  menuFinal: any = false;

  lista: any = [];
  totalLista: any = 0;

  selectedValue:any = "1";

  toggleChildren(item) {
    this.$set(item, 'expanded', !item.expanded);
  }

  getRowClass(item) {
      // Retorna a classe para indentação baseada na profundidade, se desejar
      if (item.level) {
        return `indent-${item.level}`;
      }
      return '';
    }

  empresas: any = [];
  empreendimentos: any = [];
  analiseDespesa: boolean = true;
  TipoListaDemonstrativa = 1;
  demonstrativo = new DemonstrativoResultado();
 
  options: any = {
    itemsPerPage: 50,
  };

  headers: any[] = [
    { text: "", value: "actions", align: "start", sortable: false },
    { text: "CENTRO DE CUSTO", value: "empresaNome" },
    { text: "VALOR TOTAL", value: "numeroDocumento" },
    { text: "AV(%)", value: "parcelaStr", sortable: false },
    { text: "Favorecido", value: "despesa.fornecedorNome" },
    { text: "Valor", value: "valorParcela" },
    { text: "Valor Pago", value: "valorPagoParcela", sortable: false },
    { text: "Saldo", value: "saldoParcela", sortable: false },
    { text: "Situação", value: "situacaoNome" },
    { text: "Vencimento", value: "dataVencimentoParcela" },
  ];

  ExportarExcel(table: any, worksheet?: string) {
 
  }

  filter: {
    empresasIds: any[];
    empreendimentosIds: any[];
    dataInicial: string | null;
    dataFinal: string | null;
  } = {
    empresasIds: [],
    empreendimentosIds: [],
    dataInicial: String(`${this.currentYear}-${this.currentMonths + 1}-01`),
    dataFinal: String(`${this.currentYear}-${this.currentMonths + 1}-${this.lastDayOfCurrentMonth}`)
  }

  formatDate(date, format) {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    if (format == 'yyyy-MM-dd') {
      return `${day}/${month}/${year}`;
    }
    return '';
  }
  
  formatDateDash(date, format) {
    if (!date) return '';
    const [day, month, year] = date.split('/');
    if (format == 'dd/MM/YYYY') {
      return `${year}-${month}-${day}`;
    }
    return '';
  }
handleDateSelected({ date, type }: { date: string; type: string }) {


    if (type === 'initial') {
        this.filter.dataInicial = date;
    } else if (type === 'final') {
        this.filter.dataFinal = date;
    }
    this.ObterListaCentroCustoContaGerencial();
    }
  get selecionarTodosEmpreendimentos() {
    return this.filter.empreendimentosIds.length === this.empreendimentos.length
  }

  get SelecionarAlgunsEmpreendimentos() {
    return this.filter.empreendimentosIds.length > 0 && !this.selecionarTodosEmpreendimentos
  }

  get iconEmpreendimento() {
    if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpreendimento() {
    if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
    return 'Selecionar todos'
  }

  get iconEmpresa() {
    if (this.selecionarTodosEmpresas) return 'mdi-close-box'
    if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get textEmpresa() {
    if (this.selecionarTodosEmpresas) return 'Desmarcar'
    if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
    return 'Selecionar todos'
  }

  get selecionarTodosEmpresas() {
    return this.filter.empresasIds.length === this.empresas.length
  }

  get SelecionarAlgunsEmpresas() {
    return this.filter.empresasIds.length > 0 && !this.selecionarTodosEmpresas
  }
CentroCustoListaDemonstrativa(){
  if(this.TipoListaDemonstrativa = 2){

      this.TipoListaDemonstrativa = 1
      this.ObterListaCentroCustoContaGerencial();
    }
    else{}
}
ContaGerencialListaDemonstrativa(){
   if(this.TipoListaDemonstrativa = 1){

      this.TipoListaDemonstrativa = 2
      this.ObterListaCentroCustoContaGerencial();
    }
    else{}
}

  SelecionarEmpreendimento() {
    this.$nextTick(() => {
      if (this.selecionarTodosEmpreendimentos) {
        this.filter.empreendimentosIds = []
      } else {
        this.filter.empreendimentosIds = this.empreendimentos.slice().map((x: any) => x.id)
      }
     this.ObterRelatorio();
    })
  }

  SelecionarEmpresa() {
    this.$nextTick(() => {
      if (this.selecionarTodosEmpresas) {
        this.filter.empresasIds = []
      } else {
        this.filter.empresasIds = this.empresas.slice().map((x: any) => x.id)
      }
    this.ObterRelatorio();
    })
  } 

  ObterRelatorio(){
    let empreendimentosIds = this.filter.empreendimentosIds ? this.filter.empreendimentosIds.toString() : '';
    let empresasIds =  this.filter.empresasIds ? this.filter.empresasIds.toString() : '';


    new RelatorioService().ObterRelatorioPainelDespesa(empreendimentosIds, empresasIds).then((res) => {
      this.painel = res.data;

      this.chartDataMes = [
        ['Mês', 'Valor'],
        ...this.painel.despesaRelatorio.listatotalPagarMes.map((item) => [item.mes, item.valor]),
      ];

      this.chartDataDia = [
        ['Grupo de Dias', 'Pago', 'A Pagar'],
        ...this.painel.despesaRelatorio.listaParcelaPagarDia.map((item) => [item.grupoDias, item.pago, item.aPagar]),
      ];

    });
  }

  enumTipoVisualizacao = 2;

  ObterRelatorioMes(enumTipoVisualizacao){
    this.enumTipoVisualizacao = enumTipoVisualizacao;

    let empreendimentosIds = this.filter.empreendimentosIds ? this.filter.empreendimentosIds.toString() : '';
    let empresasIds =  this.filter.empresasIds ? this.filter.empresasIds.toString() : '';


    this.chartDataCentroCusto = [
        ['Categoria', 'Total Pago', 'Total á pagar'],
        [0,0,0],
      ];

      this.chartDataContaGerencial = [
        ['Categoria', 'Pago', 'Total á pagar'],
         [0,0,0],
      ];

    new RelatorioService().ObterRelatorioMes(empreendimentosIds, empresasIds, this.enumTipoVisualizacao).then((res) => {
      this.chartDataCentroCusto = [
        ['Categoria', 'Total Pago', 'Total á pagar'],
        ...res.data.map((item) => [item.nomeCategoria, item.valorTotalPago, item.valorTotalAPagar]),
      ];

      this.chartDataContaGerencial = [
        ['Categoria', 'Pago', 'Total á pagar'],
        ...res.data.map((item) => [item.nomeCategoria, item.valorTotalPago, item.valorTotalAPagar]),
      ];
    });
  }

  tree:any = [];

  async ObterListaCentroCustoContaGerencial(){
    let empreendimentosIds = this.filter.empreendimentosIds ? this.filter.empreendimentosIds.toString() : '';
    let empresasIds =  this.filter.empresasIds ? this.filter.empresasIds.toString() : '';
  
   
    await new RelatorioService().ObterRelatorioDespesaListaDemonstraviva(empreendimentosIds, empresasIds, this.TipoListaDemonstrativa, this.filter.dataInicial, this.filter.dataFinal).then((res)=>{
      this.demonstrativo = res.data;

    
              this.tree = res.data.demonstrativos.filter(x => x.demonstrativoPaiId == null);        
    })
  }
  
  

  get currentMonth(): string {
    const monthNames = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    const currentDate = new Date();
    return monthNames[currentDate.getMonth()];
  }

  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Painel despesas', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  chartData = [
    ['Mês', 'Valor'],
  ]

  chartOptions = {
    bar: { groupWidth: '50%',},
    vAxis: { title: 'TOTAL'},
    hAxis: { textStyle: {color: 'gray'}},
    series: {
      0: {color: '#E05A1B'}
    },

    legend: {
      position: 'none',
      textStyle: {color: 'gray',},
    },
    backgroundColor: "transparent",
  }

  chartDataMes: any[] = [];

  chartOptionsMes = {
   series: {0: { type: 'bars', color: '#E05A1B' },},
    bar: { groupWidth: '50%' },
    hAxis: {  textStyle: { color: 'gray'   } },
    legend: 'none',
    textStyle: {color: 'gray'},
    backgroundColor: "transparent",
  };

  chartDataDia: any[] = [];


  chartDataContaGerencial = [
    ['Nome', 'Total Pago', 'Total Pago'],
    [0,0,0]
  ]

  chartDataCentroCusto = [
    ['Nome', 'Total á pagar', 'Total Pago'],
    [0,0,0]
  ]

  chartOptionsCentroCusto = {
     bar: { groupWidth: '50%' },
    series: {
      0: { type: 'bars', color: '#4CAF50' },
      1: { type: 'bars', color: '#E05A1B' },
    },
    
    hAxis: {
      textStyle: {
        color: 'gray'
      }
    },

    legend: 'none',
    textStyle: {color: 'gray'},
    backgroundColor: "transparent",
  };

  chartOptionsContaGerencial = {
    bar: { groupWidth: '50%' },
    series: {
      0: { type: 'bars', color: '#4CAF50' },
      1: { type: 'bars', color: '#E05A1B' },
    },
    
    hAxis: {
      textStyle: {
        color: 'gray'
      }
    },

    legend: 'none',
    textStyle: {color: 'gray'},
    backgroundColor: "transparent",
  };

  chartOptionsDia = {
    series: {
      0: { type: 'bars', color: '#4CAF50' },
      1: { type: 'bars', color: '#E05A1B' },
    },
    
    hAxis: {
      textStyle: {
        color: 'gray'
      }
    },
    seriesType: 'bars',
    legend: 'none',
    textStyle: {color: 'gray'},
    backgroundColor: "transparent",
  };

    ValorRadio() {
        this.selectedValue = "1";
        
    }

    months: string[] = []; // Defina a propriedade aqui

  // Outros métodos e propriedades
  generateMonths() {
    const monthNames = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
    const currentYear = new Date().getFullYear();

    let months: string[] = []; // Tipagem explícita

    if (this.filter.dataInicial && !this.filter.dataFinal) {
      const dataInicial = new Date(this.filter.dataInicial );
      const currentDate = new Date(dataInicial);

      while (currentDate.getFullYear() === dataInicial.getFullYear() && currentDate.getMonth() < 12) {
        const month = monthNames[currentDate.getMonth()];
        const year = currentDate.getFullYear().toString().slice(-2);
        months.push(`${month}/${year}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      this.months = months;
    } else if (this.filter.dataFinal && !this.filter.dataInicial) {
      const dataFinal = new Date(this.filter.dataFinal);
      const currentDate = new Date(dataFinal.getFullYear(), 0, 1);

      while (currentDate <= dataFinal) {
        const month = monthNames[currentDate.getMonth()];
        const year = currentDate.getFullYear().toString().slice(-2);
        months.push(`${month}/${year}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      this.months = months;
    } else if (this.filter.dataInicial && this.filter.dataFinal) {
      const dataInicial = new Date(this.filter.dataInicial);
      const dataFinal = new Date(this.filter.dataFinal);
      const currentDate = new Date(dataInicial);

      while (currentDate <= dataFinal) {
        const month = monthNames[currentDate.getMonth()];
        const year = currentDate.getFullYear().toString().slice(-2);
        months.push(`${month}/${year}`);
        currentDate.setMonth(currentDate.getMonth() + 1);
      }

      this.months = months;
    } else {
      const year = currentYear.toString().slice(-2);
      this.months = monthNames.map(month => `${month}/${year}`);
    }
  }

  mounted() {
    this.ValorRadio();
    
    this.generateMonths();
    this.ObterRelatorio();
    this.ObterRelatorioMes(this.enumTipoVisualizacao);

    this.ObterListaCentroCustoContaGerencial();

    new EmpresaService().Listar(-1, -1, ['nome'], [], '', [], '', '', 'Id, nome, nomeFantasia', '').then(
       res => this.empresas = res.data.items
     );

     new EmpreendimentoService().Listar(-1, -1, ['nome'], [], '', [], '', '', 'Id, nome', '').then(res => {
       this.empreendimentos = res.data.items}
     )
  }
  }
  
  


 

class PainelModel{
  totalCentroCusto:number = 0;
  despesaRelatorio = new DespesaRelatorio();
  listaCentroCustoModel:any = [];
}

class DespesaRelatorio{
  aVencer:number = 0;
  listaParcelaPagarDia:any = [];
  listatotalPagarMes:any = [];
  
  totalPagarAno:number = 0;
  totalPagoAno:number = 0;
  totalPagoMes:number = 0;
  vencidos:number = 0;
  vencidosHoje:number = 0;
}

class DemonstrativoResultado{
  ValorTotalPago:number = 0;
  valorTotalAPagar:number = 0;
  valorTotal:number = 0;
  demonstrativos:any = [];
  demonstrativoMesTotais:any = [];

}
// class CentroCustoContaGerencial{
//   nome:string = ""
//   av:number = 0;
//   demonstrativoId:number = 0;
//   demonstrativoPaiId:number = 0;
//   valorTotalPago:number = 0;
//   valorTotalAPagar:number = 0;
//   valorTotal:number = 0;
//   mesModel: demonstrativoMesModel =[];
//   centroCustoChildren:CentroCustoContaGerencial = [];
// }
// class demonstrativoMesModel{
//   mes:string = ""
//   pago:number = 0;
//   aPagar:number = 0;
//   valorTotal:number = 0;
//   pagoComFilhos:number = 0;
//   aPagarComFilhos:number = 0;
//   valorTotalComFilhos:number = 0;

// }
